<template>
  <div>
    <div v-if="!isContentLoaded" class="text-center w-full py-12">
      <base-svg
        class="h-4 w-4 mr-1 text-primary-red inline-block"
        src="icons/circleSpinner.svg"
        tag="span"
      />
      Loading ...
    </div>

    <template v-else>
      <style-guide-card
        v-for="directoryNode in directoryData"
        :key="directoryNode.styleGuideId"
        class="w-full"
        :model-value="true"
        :title="directoryNode.title"
        :directory-tree-props="{
          directoryTreeData: directoryNode.children,
          nameProperty: 'title',
          selectedNodeClass: 'text-primary-red font-semibold',
          folderProps: {
            folderIconContainerClass: 'flex-shrink-0 w-4',
            closeFolderIconProperty: 'directoryIcon',
            openFolderIconProperty: 'directoryIcon'
          }
        }"
      />
    </template>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'StyleGuidesCategoryList',

    components: {
        StyleGuideCard: defineAsyncComponent(() => import('./StyleGuideCard.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    setup () {
        const store = useStore();
        const isContentLoaded = computed(() => directoryData.value && directoryData.value && directoryData.value.length);
        const directoryData = computed(() => {
            return store.getters['styleGuideTree/getStyleGuideDirectory'];
        });

        return {
            directoryData,
            isContentLoaded
        };
    }
};
</script>
